@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .container {
    @apply w-full sm:max-w-[1128px];
  }
}

@supports (padding: max(0px)) {
  body,
  header,
  footer {
    padding-left: min(0vmin, env(safe-area-inset-left));
    padding-right: min(0vmin, env(safe-area-inset-right));
  }
}

* {
  @apply antialiased;
}

body {
  // @apply bg-white overflow-visible;
  background-image: url('/kittens/holographic.png');
  background-repeat: no-repeat;
  background-size: cover;
}

main {
  background-color: rgba(255,255,255,0.6);
}

#__next {
  @apply overflow-visible;
}

.btn {
  @apply focus:ring-4 font-medium rounded-full text-sm p-3 focus:outline-none transition ease-in-out delay-75 duration-300 max-w-[375px] mx-auto;
}

.btn-primary {
  @apply btn bg-violet-500 disabled:bg-violet-300 hover:bg-violet-400 text-white;
}

.btn-secondary {
  @apply border-1 border-black border-opacity-5 mb-4 text-black flex items-center justify-between shadow-button hover:shadow-md transition ease-in-out delay-75 duration-300;
}
.btn-secondary-gray {
  @apply border-1 border-black border-opacity-5 mb-4 text-black flex items-center justify-between shadow-button hover:shadow-md transition ease-in-out delay-75 duration-300;
  background: rgba(116, 116, 128, 0.08);
}

.btn-social {
  @apply btn btn-secondary rounded-full flex-nowrap;
}

.btn-link {
  @apply text-violet-500 hover:text-violet-400;
}

.btn-primary-black {
  @apply btn bg-black text-white disabled:bg-gray-800 hover:bg-gray-900;
}

button {
    &[type='submit'] { @apply mb-4}
}

.input {
  @apply h-11 rounded-lg border-1 font-medium text-sm text-black text-opacity-70 bg-white border-black border-opacity-5 placeholder-black placeholder-opacity-30 hover:border-black hover:border-opacity-20 focus:border-violet-500 focus:ring-2 ring-violet-300 transition ease-in-out delay-75 duration-300;
}

input {
  &[type='text'],
  &[type='email'],
  &[type='phone'],
  &[type='password'] {
    @apply input;
  }
}

input[type='radio'] + label .radio-check {
  @apply bg-white border-1 border-gray-500 hover:border-gray-700 p-2 shadow-radio mr-2;
}

input[type='radio']:checked + label .radio-check {
  @apply bg-violet-500 border-violet-500 transition ease-in-out delay-75 duration-300;
}

.label {
  @apply block mb-2 text-sm font-medium text-black dark:text-white;
}

.card {
  @apply border-b-1 sm:border-1 rounded-none sm:rounded-2xl bg-white py-6 px-5 sm:py-6 sm:px-8  border-black/5 mb-6;
}

.heading-1 {
  @apply text-black text-base leading-heading tracking-heading font-medium;
}
.otp {
  @apply w-full;
}

.otp input {
  @apply input mr-2 w-16;
}

.tab {
  @apply rounded-lg text-sm text-black/[0.7] font-medium h-11 flex items-center justify-center cursor-pointer;
}
.tab.active {
  @apply bg-violet-100 text-violet-500;
}
.tab.active svg > path {
  @apply fill-violet-500;
}
.tab.inactive svg > path {
  @apply fill-[#444444];
}
.iti {
  @apply w-full;
}

.checkbox {
  @apply opacity-0 absolute h-[18px] w-[18px] transition ease-in-out delay-75 duration-300;
}
.checkbox-paint {
  @apply bg-white border-2 border-gray-500 rounded-[4px] w-[18px] h-[18px] flex flex-shrink-0 justify-center items-center focus-within:border-violet-500;
}
.checkbox:checked + div {
  @apply border-violet-500 bg-violet-500;
}
.checkbox:checked + div svg {
  @apply block text-white fill-white;
}
.checkbox:checked + div svg > g > g > path {
  @apply fill-white;
}

.checkoutForm::-webkit-scrollbar {
  display: none;
}

a {
  @apply text-violet-500 hover:text-violet-400 font-medium;
}

.gradient {
  overflow: visible;
  filter: blur(10px);
}

.carousel {
  @apply flex overflow-x-scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    @apply hidden;
  }
  &-vertical {
    @apply flex-col overflow-y-scroll;
    scroll-snap-type: y mandatory;
  }
  &-item {
    @apply box-content flex flex-none;
    scroll-snap-align: start;
    &:first-of-type {
      @apply ml-8;
    }
    &:last-of-type {
      @apply mr-8;
    }
  }
  &-center .carousel-item {
    scroll-snap-align: center;
  }
  &-end .carousel-item {
    scroll-snap-align: end;
  }
}

.userhandle {
  margin-left: -86px;
  padding-left: 86px;
}

.input-validation-text {
  @apply text-sm font-medium;
  &.error {
    @apply text-red-400;
  }
  &.success {
    @apply text-green-400;
  }
}
.holographic-text {
  @apply text-transparent;
  @apply bg-left-top;
  @apply bg-auto;
  @apply bg-clip-text;
  background-image: linear-gradient(90deg,rgba(136,73,255,.5),rgba(255,212,209,.5)),url("/kittens/holographic.png");
  /* @apply bg-gradient-to-r from-pink-500 to-violet-500 */
}

.title {
  @apply text-2xl font-semibold mb-3 text-center;
}
.subtitle {
  @apply text-sm font-normal text-black text-center text-opacity-50 leading-6 mb-8;
}

@layer components {
  .card {
    @apply block transition-all duration-300 ease-in-out;
    background-color: theme('colors.white');
    border-radius: theme('borderRadius.lg');
    padding: theme('spacing.6');
  }
  .card:hover {
    box-shadow: theme('boxShadow.lg');
  }
}

.glider-contain {
  @apply h-full w-full;
}
.glider-track {
  @apply h-full;
}

.glider-dots {
  @apply sticky pb-2 z-50 bottom-0 #{!important};
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
}
.glider-dot {
  @apply bg-white bg-opacity-50 #{!important};
}
.glider-dot.active {
  @apply bg-white #{!important};
}
/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*Loading screen text animations*/

.loading-page {
  @apply transition duration-300 bg-opacity-0 ring-0 ring-offset-0 shadow-none absolute top-[50%] grow-0 h-auto #{!important};
  p {
    @apply text-center font-medium text-black/70 opacity-0 absolute;
  }
}

.download-wall, .subscription-wall {
  @apply transition duration-300 bg-opacity-0 ring-0 ring-offset-0 shadow-none absolute top-0 grow-0 #{!important};
  .title {
    @apply text-3xl font-semibold my-3 text-center;
  }
  /* p {
    @apply absolute;
  } */
}

.section {
  @apply bg-white rounded-t-3xl md:rounded-3xl ring-offset-2 ring-2 ring-white/10 ring-offset-white/10 shadow-xl;
}

.loading-text-2 {
  animation-delay: 2500ms;
}
.loading-text-3 {
  animation-delay: calc(2500ms * 2);
}
.loading-text-4 {
  animation-delay: calc(2500ms * 3);
}

.btn {
  @apply inline-flex items-center justify-center text-center cursor-pointer select-none transition duration-200 ease-in-out flex-shrink-0;
  &:focus {
    @apply outline-none;
  }

  /* disabled */
  &-disabled,
  &[disabled] {
    @apply pointer-events-none;
    @apply opacity-75;
  }
  /* sizes */
  & {
    @apply px-4 leading-loose h-12 text-sm;
  }
  &-lg {
    @apply px-6 leading-loose h-16 text-lg;
  }
  &-sm {
    @apply px-3 leading-8 h-8 text-sm;
  }
  &-xs {
    @apply px-2 text-xs leading-relaxed h-6;
  }
  &-wide {
    @apply w-64;
  }

  /* block */
  &-block {
    @apply w-full;
  }


  /* shapes */
  &-square {
    &.btn-xs {
      @apply h-6 w-6 p-0;
    }
    &.btn-sm {
      @apply h-8 w-8 p-0;
    }
    & {
      @apply h-12 w-12 p-0;
    }
    &.btn-lg {
      @apply h-16 w-16 p-0;
    }
  }
  &-circle {
    &.btn-xs {
      @apply rounded-full h-6 w-6 p-0;
    }
    &.btn-sm {
      @apply rounded-full h-8 w-8 p-0;
    }
    & {
      @apply rounded-full h-12 w-12 p-0;
    }
    &.btn-lg {
      @apply rounded-full h-16 w-16 p-0;
    }
  }

  /* loading */
  &.loading {
    &,
    &:hover {
      @apply pointer-events-none;
    }
    &:before {
      @apply h-4 w-4 rounded-full mr-2 border-2;
      animation: spin 2s linear infinite;
      content: "";
      border-top-color: transparent;
      border-left-color: transparent;
      border-bottom-color: currentColor;
      border-right-color: currentColor;
    }
  }
}
@media (prefers-reduced-motion: reduce) {
  .btn.loading:before{
    animation: spin 10s linear infinite;
  }
}
/* group */
.btn-group {
  @apply flex flex-wrap;
  & > input[type="radio"].btn {
    @apply appearance-none;
  }
  & > input[type="radio"].btn:before {
    content: attr(data-title);
  }
}